import axios from 'axios'
import apiRoutes from "../../apiRoutes";

const namespace = "USER__"
export const actionNames = {
  ON_USER_LOGGED_IN: namespace + "ON_USER_LOGGED_IN",
  FETCH_SELF: namespace + "FETCH_SELF",
}


const state = {
  currentUser: {},
}

const getters = {
};

const actions = {
  [actionNames.ON_USER_LOGGED_IN]: async ({ dispatch }) => {
    await dispatch(actionNames.FETCH_SELF)
  },
  [actionNames.FETCH_SELF]: async ({ commit }) => {
    const response = await axios.get(apiRoutes.user.getSelf())
    commit(mutationNames.CURRENT_USER, response.data)
  }
};

const mutationNames = {
  CURRENT_USER: namespace + "CURRENT_USER"
}

const mutations = {
  [mutationNames.CURRENT_USER]: (state, user) => {
    state.user =  { ...user }
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
