<template>
  <span class="nav-link" :class="{'active': isActive}">
      <span class="icon">
        <i class="mdi mdi-24px" :class="iconClass"></i>
      </span>
      {{ text }}
  </span>

</template>

<script>
export default {
name: "NavLink",
  props: {
    text: String,
    iconClass: String,
    isActive: Boolean,
  },
}
</script>

<style scoped lang="scss">
@use '@/styles/variables/colors';

.icon {
  margin-right: 0.75em;
}
.nav-link {
  padding: .5em 1.5em;
  margin: 0 0.75em;
  border-radius: 10px;

  color: colors.$main-menu-text;
  font-family: 'Neue Haas Grotesk Display Pro 55 Roman', sans-serif;
  text-decoration: none;

  display: flex;
  align-items: center;

  &:hover {
    background: #035e6a;
  }

  &.active {
    background: colors.$main-menu-contrast-darker;
  }
}
</style>