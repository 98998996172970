import apiRoutes from "../../apiRoutes";
import axios from "axios";
import mutationNames from "./mutations";

const namespace = "ORDER_FULFILLMENT__";

const actionNames = {
    REFRESH_ORDER_LIST: namespace + "REFRESH_ORDER_LIST",
    REFRESH_CURRENT_ORDER: namespace + "REFRESH_CURRENT_ORDER",
    REFRESH_DATA: namespace + "REFRESH_DATA",
    SELECT_CURRENT_ORDER: namespace + "SELECT_CURRENT_ORDER",
    SET_FULFILLMENT_STATUS: namespace + "SET_FULFILLMENT_STATUS",
    CREATE_CONSIGNMENT: namespace + "CREATE_CONSIGNMENT",
    SET_INVOICE_SENT_STATUS: namespace + "SET_INVOICE_SENT_STATUS",
    SET_ITEM_SENT_BY_EMAIL_STATUS: namespace + "SET_ITEM_SENT_BY_EMAIL_STATUS",
    SET_ITEM_SENT_BY_POST_STATUS: namespace + "SET_ITEM_SENT_BY_POST_STATUS",
    CREATE_ORDER_COMMENT: namespace + "CREATE_ORDER_COMMENT",
}
export default actionNames;

export const actions = {
    [actionNames.CREATE_CONSIGNMENT]: (context) => {
        return new Promise((resolve) => {
            var items = context.state.currentOrder.items
                .filter((item, index) => context.state.orderItemSelectionMask[index])
            console.log("CREATE_CONSIGNMENT for:", items)
            var body = {
                order: {id: context.state.currentOrder.id},
                items
            }
            var url = apiRoutes.shipping.createConsignment()
            axios.post(url, body)
                .then(() => context.dispatch(actionNames.REFRESH_DATA))
                .then(() => resolve())
        })
    },
    [actionNames.SET_ITEM_SENT_BY_EMAIL_STATUS]: (context, value) => {
        return new Promise((resolve) => {
            var items = context.state.currentOrder.items
                .filter((item, index) => context.state.orderItemSelectionMask[index])
                .map(item => {return {id: item.id}})
            console.log("SET_ITEM_SENT_BY_EMAIL_STATUS for:", items, "New value:", value)
            var orderId = context.state.currentOrder.id
            var body = {
                items,
                value
            }
            var url = apiRoutes.order.setItemSentByEmailStatus(orderId)
            axios.patch(url, body)
                .then(() => context.dispatch(actionNames.REFRESH_DATA))
                .then(() => resolve())
        })
    },
    [actionNames.SET_ITEM_SENT_BY_POST_STATUS]: (context, value) => {
        return new Promise((resolve) => {
            var items = context.state.currentOrder.items
                .filter((item, index) => context.state.orderItemSelectionMask[index])
                .map(item => {return {id: item.id}})
            console.log("SET_ITEM_SENT_BY_POST_STATUS for:", items, "New value:", value)
            var orderId = context.state.currentOrder.id
            var body = {
                items,
                value
            }
            var url = apiRoutes.order.setItemSentByPostStatus(orderId)
            axios.patch(url, body)
                .then(() => context.dispatch(actionNames.REFRESH_DATA))
                .then(() => resolve())
        })
    },
    [actionNames.REFRESH_DATA]: (context) => {
        return new Promise((resolve, reject) => {
            var actions = []
            actions.push(context.dispatch(actionNames.REFRESH_ORDER_LIST))
            actions.push(context.dispatch(actionNames.REFRESH_CURRENT_ORDER))
            Promise.all(actions)
                .then(() => resolve())
                .catch(reject)
        })
    },
    [actionNames.REFRESH_ORDER_LIST]: (context) => {
        return new Promise((resolve) => {
            var params = {isFulfilled: false}
            var url = apiRoutes.order.getAll()
            axios.get(url,{params})
                .then(res => {
                    context.commit(mutationNames.SET_ORDER_LIST, res.data.orders)
                })
                .then(() => resolve())
        })
    },
    [actionNames.REFRESH_CURRENT_ORDER]: (context) => {
        return new Promise((resolve, reject) => {
            var orderId = context.state.currentOrder.id
            if (!orderId) {
                reject("Can not refresh - the current order was not set")
                return
            }
            var url = apiRoutes.order.getOne(orderId)
            axios.get(url)
                .then(res => {
                    context.commit(mutationNames.SET_CURRENT_ORDER, res.data)
                    resolve()
                })
        })
    },
    [actionNames.SELECT_CURRENT_ORDER]: (context, order) => {
        return new Promise((resolve) => {
            var shouldResetItemSelection = false
            if (order.id !== context.state.currentOrder.id) {
                shouldResetItemSelection = true;
            }
            context.commit(mutationNames.SET_CURRENT_ORDER, order)
            if (shouldResetItemSelection) {
                context.commit(mutationNames.RESET_ORDER_ITEM_SELECTION)
            }
            resolve()
        })
    },
    [actionNames.SET_FULFILLMENT_STATUS]: (context, value) => {
        return new Promise((resolve) => {
            var order = context.state.currentOrder
            var body = {isFulfilled: value}
            axios.patch(apiRoutes.order.setFulfillmentStatus(order.id), body)
                .then(() => context.commit(mutationNames.RESET_STATE))
                .then(() => context.dispatch(actionNames.REFRESH_ORDER_LIST))
                .then(() => resolve())
        })
    },
    [actionNames.SET_INVOICE_SENT_STATUS]: (context, value) => {
        return new Promise((resolve) => {
            var order = context.state.currentOrder
            var body = {invoiceIsSent: value}
            axios.patch(apiRoutes.order.setInvoiceSentStatus(order.id), body)
                .then(() => context.dispatch(actionNames.REFRESH_DATA))
                .then(() => resolve())
        })
    },
    [actionNames.CREATE_ORDER_COMMENT]: async (context, text) => {
        var orderId = context.state.currentOrder.id
        var userId = context.rootState.user.user.id
        var body = {
            text,
            user: {
                id: userId
            },
            order: {
                id: orderId
            }
        }
        await axios.post(apiRoutes.orderComment.create(), body)
        await context.dispatch(actionNames.REFRESH_DATA)
    },
};