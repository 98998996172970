<template>
  <div class="login-page">
    <div class="login-form">
      <AppPanel>
        <BoxSpacer>
          <div class="column has-inner-gaps">
            <div class="spacer mb4"></div>
            <div class="logo column justify-center align-center">
              <CormorantEmblem />
            </div>
            <form class="column has-inner-gaps" @submit.prevent="signIn">
              <label class="form-group">
                <span class="form-label">Brukernavn</span>
                <input type="text" id="username" class="form-field" v-model="username">
              </label>
              <label class="form-group">
                <span class="form-label">Passord</span>
                <input type="password" class="form-field" v-model="password">
              </label>
              <span>
                <AppButton
                    text="Logg inn"
                    icon-class="mdi-login"
                    @click="signIn"
                />
              </span>
            </form>
          </div>
        </BoxSpacer>
      </AppPanel>
    </div>
  </div>
</template>

<script>
import { AUTH_SIGNIN_REQUEST } from '../store/auth/actions'
import AppPanel from "@/components/Ui/Panel.vue";
import BoxSpacer from "@/components/Ui/Layout/BoxSpacer.vue";
import CormorantEmblem from "@/components/Ui/CormorantEmblem.vue";
import AppButton from "@/components/Ui/AppButton.vue";

export default {
  name: 'LoginPage',
  components: {
    AppButton,
    CormorantEmblem,
    BoxSpacer,
    AppPanel,
  },
  data () {
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    signIn() {
      const { username, password } = this
      console.log("Signin submitted, dispatching AUTH_SIGNIN_REQUEST ...")
      this.$store.dispatch(AUTH_SIGNIN_REQUEST, { username, password })
    }
  }
}
</script>
<style scoped lang="scss">
@use '@/styles/variables/layout';

.login-page {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: center;
}

.login-form {
  margin-top: auto;
  margin-bottom: auto;
  width: min(100%,  400px);

  overflow-y: auto;
}

@media(max-width: layout.$breakpoint-mobile) {
  .login-form {
    margin-top: 0;
  }
}
</style>