import { createRouter, createWebHashHistory } from 'vue-router'
import Login from './pages/LoginPage.vue'
import store from './store/'

const proceedIfNotAuthenticated = (to, from, next) => {
    console.log("proceedIfNotAuthenticated:" , !store.getters.isAuthenticated)
    if (!store.getters.isAuthenticated) {
      next()
      return
    }
    next('/')
  }
  
const ProceedIfAuthenticated = (to, from, next) => {
console.log("ProceedIfAuthenticated:" , store.getters.isAuthenticated)
if (store.getters.isAuthenticated) {
  next()
  return
}
next('/login')
}

export const routes = {
    login: "login",
    home: "home",
    customerList: "customerList",
    customer: "customer",
    orderEmails: "orderEmails",
    newOrder: "newOrder",
    orderListPage: "orderListPage",
    orderReportPage: "orderReportPage",
    leadsPage: "leadsPage",
    orderFulfillmentPage: "orderFulfillmentPage",
}

export default createRouter({
    history: createWebHashHistory(),
    routes: [
      {
          path: '/login/',
          name: routes.login,
          component: Login,
          beforeEnter: proceedIfNotAuthenticated
      },  
      {
            path: '/',
            name: routes.home,
            component: () => import("@/pages/DashboardPage.vue"),
            beforeEnter: ProceedIfAuthenticated
        },
        {
            path: '/customers/',
            name: routes.customerList,
            component: () => import("@/pages/CustomerListPage.vue"),
            beforeEnter: ProceedIfAuthenticated
        },
        {
          path: '/customers/:id',
          name: routes.customer,
          component: () => import("@/pages/CustomerPage.vue"),
          beforeEnter: ProceedIfAuthenticated
      },
        {
          path: '/orders/email',
          name: routes.orderEmails,
          component: () => import("@/pages/OrderEmailsPage.vue"),
          beforeEnter: ProceedIfAuthenticated
        },
        {
          path: '/orders/new',
          name: routes.newOrder,
          component: () => import("@/pages/NewOrderPage.vue"),
          beforeEnter: ProceedIfAuthenticated
        },
        {
            path: '/orders',
            name: routes.orderListPage,
            component: () => import("@/pages/OrderListPage.vue"),
            beforeEnter: ProceedIfAuthenticated
        },
        {
            path: '/orders/report',
            name: routes.orderReportPage,
            component: () => import("@/pages/OrderReportPage.vue"),
            beforeEnter: ProceedIfAuthenticated
        },
        {
            path: '/leads',
            name: routes.leadsPage,
            component: () => import("@/pages/LeadsPage.vue"),
            beforeEnter: ProceedIfAuthenticated
        },
        {
            path: '/orders/fulfillment',
            name: routes.orderFulfillmentPage,
            component: () => import("@/pages/OrderFulfillmentPage.vue"),
            beforeEnter: ProceedIfAuthenticated
        },
    ]
})