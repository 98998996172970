<template>
  <transition name="modal">
    <component
        :is="modal.component"
        v-bind="modal.props"
        :context="modal.$context"
    />
  </transition>

</template>

<script>
import Modal from "@/components/Ui/ModalBackdrop.vue";
import modalService from "@/utils/modal/modalService";
import  { shallowRef } from 'vue'

export default {
name: "ModalRoot",
  components: { Modal },
  props: {
  },
  setup() {
    return {
      modal: shallowRef({}),
    }
  },
  created() {
    modalService.onShowModal(( event ) => {
      this.modal = event
      event.$context.onClose(() => { this.modal = {} })
    })
  },
}
</script>

<style scoped lang="scss">
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.25s ease;
}
.modal-enter,
.modal-leave-to {
  opacity: 0;
}
</style>