<template>
  <svg
      class="cormorant-emblem"
      :fill="color"
      width="100%"
      height="100%"
      viewBox="0 0 92 47"
      xmlns="http://www.w3.org/2000/svg">
    <path
        d="M91.5107 22.0467C91.5592 22.5803 91.171 23.2838 90.346 24.1571C89.5696 25.0304 88.4292 25.8067 86.9248
          26.4859C85.4205 27.1651 83.6735 27.5047 81.6839 27.5047L78.4083 21.3189L81.1015 28.2325C79.7428 29.5909
          77.9958 30.731 75.8606 31.6528C73.7739 32.5261 71.299 33.3994 68.4359 34.2727C65.961 35.0975 64.4324 35.6554
          63.85 35.9465C62.7824 34.5881 61.545 33.3994 60.1377 32.3806C58.7304 31.3617 57.5415 30.8523 56.5709
          30.8523C56.5224 31.1919 56.4981 31.6771 56.4981 32.3078C56.4981 34.491 57.0077 36.8926 58.0267 39.5125C58.1723
          40.0461 58.4877 40.5798 58.973 41.1135C59.4583 41.5987 60.1619 42.2294 61.084 43.0056C62.4913 44.2185 63.4133
          45.1161 63.85 45.6983C63.5103 45.7953 63.1464 45.8923 62.7582 45.9894C62.4185 46.0864 62.0788 46.1834 61.7391
          46.2805L59.4098 44.0245L61.084 46.426C60.0164 46.6201 58.9002 46.7414 57.7356 46.7899C56.5709 46.8384 55.3577
          46.8626 54.096 46.8626C53.999 46.7171 53.9262 46.5716 53.8776 46.426C53.8291 46.2805 53.7806 46.1349 53.732
          45.9894L51.6939 45.9166C50.7719 45.771 49.3888 45.6983 47.5448 45.6983C46.089 45.6983 44.6817 45.7468 43.3229
          45.8438C42.3524 45.9408 41.6487 45.9894 41.212 45.9894C41.0178 45.9894 40.9208 45.8196 40.9208 45.4799C40.9208
          45.1403 41.0178 44.9705 41.212 44.9705C43.1045 44.9705 44.4876 44.7522 45.3611 44.3156C46.2346 43.8789 46.6713
          42.9814 46.6713 41.6229V39.8763C46.186 39.4397 45.725 38.7605 45.2883 37.8387C44.8515 36.9168 44.3662 35.6797
          43.8324 34.1272L43.2501 32.5989C42.4251 32.8415 41.6244 33.3024 40.848 33.9816C40.1201 34.6608 39.3437 35.6554
          38.5187 36.9654C37.4996 36.9654 36.2864 36.6985 34.8791 36.1648C33.5204 35.6312 31.5793 34.7579 29.0558
          33.545C26.9691 32.5261 25.2707 31.7499 23.9604 31.2162C22.6502 30.634 21.4855 30.2701 20.4665 30.1246L22.5046
          23.7932L19.1562 30.0518C17.2151 30.1974 15.2983 30.2701 13.4057 30.2701C9.71763 30.2701 6.5876 30.0276 4.01565
          29.5424C1.44369 29.0572 0.157715 28.475 0.157715 27.7958C0.157715 26.4374 1.75912 24.618 4.96193
          22.3378C8.16475 20.0575 11.7558 18.0198 15.735 16.2247C19.7628 14.4296 22.7715 13.5321 24.7611 13.5321C25.6832
          13.5321 26.6052 13.7504 27.5272 14.187C28.4978 14.6237 29.7837 15.3029 31.3851 16.2247C32.5983 16.9525 33.6174
          17.5347 34.4424 17.9713C35.2673 18.3594 36.0438 18.6263 36.7717 18.7718C37.9849 18.9659 39.4407 19.0629
          41.1392 19.0629C41.1392 18.6748 41.212 18.3594 41.3575 18.1168C41.5517 17.8258 41.8186 17.5104 42.1582
          17.1708C42.4979 16.7827 42.7648 16.4188 42.959 16.0792C43.1531 15.691 43.2501 15.2301 43.2501 14.6965C43.2501
          13.6291 43.517 12.7073 44.0508 11.931C44.6331 11.1548 45.4339 10.3543 46.4529 9.5295C46.8897 9.18988 47.2536
          8.85027 47.5448 8.51066C47.836 8.17104 47.9815 7.87995 47.9815 7.63737C47.9815 7.00666 47.6661 6.42446 47.0353
          5.89079C46.4044 5.30859 45.7978 4.94472 45.2155 4.79917C44.8758 4.65362 44.4876 4.41104 44.0508
          4.07143C43.6141 3.73182 43.3229 3.48924 43.1773 3.34369C42.9832 3.24666 42.4737 3.02833 41.6487
          2.68872C40.8723 2.3491 40.1929 2.10652 39.6106 1.96098C39.0282 1.76692 38.5429 1.66988 38.1547 1.66988C37.815
          1.66988 37.5724 1.71839 37.4268 1.81543C37.4268 1.23324 37.5967 0.820851 37.9364 0.57827C38.3246 0.335689
          38.7371 0.238656 39.1738 0.287172C39.465 0.335687 40.0716 0.456978 40.9936 0.651044C41.9641 0.796593 42.692
          0.845109 43.1773 0.796587C43.8082 0.311428 44.9243 0.0688477 46.5257 0.0688477C47.0595 0.0688477 47.7632
          0.117365 48.6367 0.214399C49.8499 0.359945 50.966 1.06343 51.9851 2.32485C53.0041 3.58627 53.5137 4.84769
          53.5137 6.10911C53.5137 7.37053 53.2225 8.4864 52.6402 9.45672C52.1064 10.427 51.3785 11.4216 50.4564
          12.4405C49.7285 13.2652 49.3646 13.7989 49.3646 14.0415C49.3646 14.5752 49.6072 15.0603 50.0925 15.497C50.5778
          15.8851 51.3299 16.346 52.349 16.8797C53.4166 17.4134 54.1445 17.8743 54.5328 18.2624C55.7945 18.3594 56.8135
          18.4079 57.59 18.4079L60.7928 18.3352C61.9575 18.2867 63.1949 17.9713 64.5051 17.3891C65.8154 16.8069 67.3683
          15.9821 69.1638 14.9148C70.6681 14.0415 71.9056 13.3865 72.8761 12.9499C73.8467 12.5132 74.6959 12.2949
          75.4238 12.2949C78.6752 12.2949 81.514 12.8043 83.9404 13.8232C86.3668 14.842 88.2108 16.1034 89.4725
          17.6074C90.7828 19.1114 91.4622 20.5912 91.5107 22.0467ZM48.2727 41.8412C48.2727 43.0541 48.6367 43.8789
          49.3646 44.3156C50.0925 44.7037 51.427 44.922 53.3681 44.9705C53.174 44.4854 52.81 43.4423 52.2762
          41.8412C51.0145 41.4531 49.68 40.9437 48.2727 40.313V41.8412Z"
    />
  </svg>
</template>

<script>
export default {
name: "CormorantEmblem",
  props: {
    color: { type: String, default: '#7EDCE2' },
  },
}
</script>

<style scoped lang="scss">
@use '@/styles/variables/colors';

</style>