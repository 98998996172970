import axios from 'axios'
import apiRoutes from "../../apiRoutes";
import getClone from 'rfdc'
import {add, format, startOfMonth, startOfYear, endOfYear} from 'date-fns'

const clone = getClone()
const dateFormatStr = 'yyyy-MM-dd'

const getState = function() {
    return {
        orders: [],
        filters: [
            {
                name: "Last 30 days",
                label: "Siste 30 dager",
            },
            {
                name: "This month",
                label: "Denne måneden",
            },
            {
                name: "This year",
                label: "I år",
            },
            {
                name: "Last year",
                label: "I fjor",
            },
        ],
        activeFilterIndex: 0,
    }
}

const nameSpace = "ORDER_LIST__"
export const actionNames = {
    REFRESH_ORDER_LIST: nameSpace + "REFRESH_ORDER_LIST",
    SET_ACTIVE_FILTER: nameSpace + "SET_ACTIVE_FILTER",
}

export const mutationNames = {
    SET_ORDER_LIST: nameSpace + "SET_ORDER_LIST",
    SET_ACTIVE_FILTER: nameSpace + "SET_ACTIVE_FILTER",
    RESET_STATE: nameSpace + "RESET_STATE",
}

export default {
    state: getState(),
    getters: {
        getfilterDates: (state) => {
            var activeFilter = state.filters[state.activeFilterIndex]
            console.log("activeFilter", activeFilter)
            var result = {}
            if (activeFilter.name === "Last 30 days") {
                result.fromDate = add(new Date(), {days: -30})
            }
            if (activeFilter.name === "This month") {
                // eslint-disable-next-line no-redeclare
                result.fromDate = startOfMonth(new Date())
            }
            if (activeFilter.name === "This year") {
                // eslint-disable-next-line no-redeclare
                result.fromDate = startOfYear(new Date())
            }
            if (activeFilter.name === "Last year") {
                // eslint-disable-next-line no-redeclare
                result.fromDate = startOfYear(add(new Date(), {years: -1}))
                result.toDate = endOfYear(result.fromDate)
            }

            if (result.fromDate) {
                result.fromDate = format(result.fromDate, dateFormatStr)
            }
            if (result.toDate) {
                result.toDate = format(result.toDate, dateFormatStr)
            }
            return result
        }
    },
    actions: {
        [actionNames.REFRESH_ORDER_LIST]: (context) => {
            return new Promise((resolve) => {
                var  params = {...context.getters.getfilterDates, sortDescending: true}
                console.log(params)
                var url = apiRoutes.order.getAll()
                axios.get(url,{params})
                    .then(res => {
                        context.commit(mutationNames.SET_ORDER_LIST, res.data.orders)
                        resolve()
                    })
            })
        },
        [actionNames.SET_ACTIVE_FILTER]: (context, filterIndex) => {
            return new Promise((resolve) => {
                console.log("Triggered action: SET_ACTIVE_FILTER with filterIndex:", filterIndex)
                context.commit(mutationNames.SET_ACTIVE_FILTER, filterIndex)
                context.dispatch(actionNames.REFRESH_ORDER_LIST)
                    .then(() => {
                        resolve()
                    })
            })
        }
    },
    mutations: {
        [mutationNames.RESET_STATE]: (state) => {
            Object.assign(state, getState())
        },
        [mutationNames.SET_ORDER_LIST]: (state, orders) => {
            state.orders = clone(orders)
        },
        [mutationNames.SET_ACTIVE_FILTER]: (state, activeFilterIndex) => {
            console.log("Mutation setting active filter to:", activeFilterIndex)
            state.activeFilterIndex = activeFilterIndex
        },
    },
};