
<template>
  <div class="app">
    <MainMenu />
    <main class="page-content">
      <ModalRoot />
      <router-view />
    </main>
  </div>
</template>

<script>
  import MainMenu from './components/MainMenu.vue'
  import ModalRoot from "@/components/Ui/ModalRoot.vue";
  export default {
    components: {
      ModalRoot,
      MainMenu
    },
  }
</script>
<style scoped lang="scss">
@use '@/styles/variables/_layout.scss';

.page-content {
  margin-top: 5.1rem;
  margin-left: 4.5rem;
}

.app {
  display: flex;
}

@media (max-width: layout.$breakpoint-small-screen) {
  .page-content  {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}

@media (max-width: layout.$breakpoint-hamburger-menu) {
  .app {
    flex-direction: column;
  }

  .page-content  {
    margin: 1rem;
  }
}

@media (max-width: layout.$breakpoint-mobile) {
  .page-content  {
    margin: 1rem 0.25rem;
  }
}

</style>
