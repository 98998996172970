<template>
    <router-link :to="{name: 'home'}" class="router-link">
      <div class="logo">
        <CormorantEmblem color="#7EDCE2" />
      <span class="logotype">skarv</span>
    </div>
    </router-link>
</template>

<script>
import CormorantEmblem from "@/components/Ui/CormorantEmblem.vue";

export default {
name: "SiteLogoStacked",
  props: {
    color: String,
  },
  components: {
    CormorantEmblem,
  },
}
</script>

<style scoped lang="scss">
@use '@/styles/variables/colors';

.logo {
  display: flex;
  flex-direction: column;
  align-items: center;

  align-self: center;

  width: 92px;
}

.logotype {
  display: block;
  font-size: 38px;
  font-family: 'Baloo Thambi 2', sans-serif;
  font-weight: 400;
  margin-top: -0.25em;

  color: colors.$main-menu-text-lighter;
}

.router-link {
  text-decoration: none;
}
</style>