// Trigger a function when a click is registered outside the element
// The input must be a function (assignments, for instance, are not supported, as they are for @click handlers)

const clickedOutsideDirective = {
    mounted(element, binding) {
        const clickEventHandler = (event) => {
            if (element !== event.target
                && !element.contains(event.target)) {
                binding.value(event)
            }
        }
        element.__clickedOutsideHandler__ = clickEventHandler
        document.addEventListener("click", clickEventHandler, { passive: true })
    },
    unmounted(element) {
        document.removeEventListener("click", element.__clickedOutsideHandler__)
    },
}

export default clickedOutsideDirective