<template>
<div class="box-spacer">
  <slot></slot>
</div>
</template>

<script>
export default {
  name: "BoxSpacer",
  props: {
  },
}
</script>

<style scoped lang="scss">
@use '@/styles/variables/layout';

.box-spacer {
  padding: layout.$gutter;
}
</style>