import mutationNames from "./mutations";
import axios from "axios";
import apiRoutes from "../../apiRoutes";
import router from "../../router";
import {emptyStringToNull} from "../../utils/emptyStringToNull";

const namespace = "NEW_ORDER__";

const actionNames = {
    ORG_NUMBER_CHANGED: namespace + "ORG_NUMBER_CHANGED",
    CUSTOMER_NAME_CHANGED: namespace + "CUSTOMER_NAME_CHANGED",
    SHIPPING_EMAIL_CHANGED: namespace + "SHIPPING_EMAIL_CHANGED",
    BILLING_EMAIL_CHANGED: namespace + "BILLING_EMAIL_CHANGED",
    SHIPPING_ADDRESS_CHANGED: namespace + "SHIPPING_ADDRESS_CHANGED",
    SHIPPING_ADDRESS_LINE1_CHANGED: namespace + "SHIPPING_ADDRESS_LINE1_CHANGED",
    SHIPPING_ADDRESS_LINE2_CHANGED: namespace + "SHIPPING_ADDRESS_LINE2_CHANGED",
    SHIPPING_ADDRESS_POST_AREA_NAME_CHANGED: namespace + "SHIPPING_ADDRESS_POST_AREA_NAME_CHANGED",
    SHIPPING_ADDRESS_POST_CODE_CHANGED: namespace + "SHIPPING_ADDRESS_POST_CODE_CHANGED",
    BILLING_ADDRESS_CHANGED: namespace + "BILLING_ADDRESS_CHANGED",
    BILLING_ADDRESS_LINE1_CHANGED: namespace + "BILLING_ADDRESS_LINE1_CHANGED",
    BILLING_ADDRESS_LINE2_CHANGED: namespace + "BILLING_ADDRESS_LINE2_CHANGED",
    BILLING_ADDRESS_POST_AREA_NAME_CHANGED: namespace + "BILLING_ADDRESS_POST_AREA_NAME_CHANGED",
    BILLING_ADDRESS_POST_CODE_CHANGED: namespace + "BILLING_ADDRESS_POST_CODE_CHANGED",
    DATE_CHANGED: namespace + "DATE_CHANGED",
    CUSTOMERS_REFERENCE_CHANGED: namespace + "CUSTOMERS_REFERENCE_CHANGED",
    USER_ID_CHANGED: namespace + "USER_ID_CHANGED",
    COMMENT_CHANGED: namespace + "COMMENT_CHANGED",
    RESET_STATE: namespace + "RESET_STATE",
    FETCH_CUSTOMER: namespace + "FETCH_CUSTOMER",
    NEW_ORDER: namespace + "NEW_ORDER",
    SET_EMAIL: namespace + "SET_EMAIL",
    FETCH_COMPANY_LOOKUP_RESULT: namespace + "FETCH_COMPANY_LOOKUP_RESULT",
    FETCH_USERS: namespace + "FETCH_USERS",
    ADD_ITEM: namespace + "ADD_ITEM",
    UPDATE_ITEM: namespace + "UPDATE_ITEM",
    SUBMIT_CUSTOMER: namespace + "SUBMIT_CUSTOMER",
    CREATE_CUSTOMER: namespace + "CREATE_CUSTOMER",
    UPDATE_CUSTOMER: namespace + "UPDATE_CUSTOMER",
    SUBMIT_ORDER: namespace + "SUBMIT_ORDER",
    SALES_PRICE_CHANGED: namespace + "SALES_PRICE_CHANGED",
    ORDER_SUBMITTED: namespace + "ORDER_SUBMITTED",
    CUSTOMER_PHONE_NUMBER_CHANGED: namespace + "CUSTOMER_PHONE_NUMBER_CHANGED",
    CUSTOMER_NOTE_CHANGED: namespace + "CUSTOMER_NOTE_CHANGED",
    SHIPPING_ADDRESS_VALIDATION_STATUS_CHANGED: namespace + "SHIPPING_ADDRESS_VALIDATION_STATUS_CHANGED",
    REMOVE_SHIPPING_ADDRESS: namespace + "REMOVE_SHIPPING_ADDRESS",
}
export default actionNames;

export const actions = {
    [actionNames.ORG_NUMBER_CHANGED]: (context, value) => {
        return new Promise((resolve) => {
            console.log("triggered action -> ORG_NUMBER_CHANGED")
            console.log(`value passed: '${value}'`)
            context.commit(actionNames.ORG_NUMBER_CHANGED, value);
            resolve()
        })
    },
    [actionNames.CUSTOMER_NAME_CHANGED]: (context, value) => {
        return new Promise((resolve) => {
            console.log("triggered action -> CUSTOMER_NAME_CHANGED")
            console.log("value passed:", value)
            context.commit(actionNames.CUSTOMER_NAME_CHANGED, value);
            resolve()
        })
    },
    [actionNames.CUSTOMER_PHONE_NUMBER_CHANGED]: (context, value) => {
        return new Promise((resolve) => {
            console.log("triggered action -> CUSTOMER_PHONE_NUMBER_CHANGED")
            console.log("value passed:", value)
            context.commit(mutationNames.CUSTOMER_PHONE_NUMBER_CHANGED, value);
            resolve()
        })
    },
    [actionNames.CUSTOMER_NOTE_CHANGED]: (context, value) => {
        return new Promise((resolve) => {
            console.log("triggered action -> CUSTOMER_NOTE_CHANGED")
            console.log("value passed:", value)
            context.commit(mutationNames.CUSTOMER_NOTE_CHANGED, value);
            resolve()
        })
    },
    [actionNames.SHIPPING_EMAIL_CHANGED]: (context, value) => {
        return new Promise((resolve) => {
            console.log("triggered action -> SHIPPING_EMAIL_CHANGED")
            console.log("value passed:", value)
            value = emptyStringToNull(value)
            context.commit(actionNames.SHIPPING_EMAIL_CHANGED, value);
            resolve()
        })
    },
    [actionNames.BILLING_EMAIL_CHANGED]: (context, value) => {
        return new Promise((resolve) => {
            console.log("triggered action -> BILLING_EMAIL_CHANGED")
            console.log("value passed:", value)
            // value = emptyStringToNull(value)
            context.commit(actionNames.BILLING_EMAIL_CHANGED, value);
            resolve()
        })
    },
    [actionNames.BILLING_ADDRESS_CHANGED]: (context, value) => {
        return new Promise((resolve) => {
            console.log("triggered action -> BILLING_ADDRESS_CHANGED")
            console.log("value passed:", value)
            context.commit(mutationNames.SET_BILLING_ADDRESS, value);
            resolve()
        })
    },
    [actionNames.BILLING_ADDRESS_LINE1_CHANGED]: (context, value) => {
        return new Promise((resolve) => {
            console.log("triggered action -> BILLING_ADDRESS_LINE1_CHANGED")
            console.log("value passed:", value)
            context.commit(actionNames.BILLING_ADDRESS_LINE1_CHANGED, value);
            resolve()
        })
    },
    [actionNames.BILLING_ADDRESS_LINE2_CHANGED]: (context, value) => {
        return new Promise((resolve) => {
            console.log("triggered action -> BILLING_ADDRESS_LINE2_CHANGED")
            console.log("value passed:", value)
            context.commit(actionNames.BILLING_ADDRESS_LINE2_CHANGED, value);
            resolve()
        })
    },
    [actionNames.BILLING_ADDRESS_POST_CODE_CHANGED]: (context, value) => {
        return new Promise((resolve) => {
            console.log("triggered action -> BILLING_ADDRESS_POST_CODE_CHANGED")
            console.log("value passed:", value)
            context.commit(actionNames.BILLING_ADDRESS_POST_CODE_CHANGED, value);
            resolve()
        })
    },
    [actionNames.BILLING_ADDRESS_POST_AREA_NAME_CHANGED]: (context, value) => {
        return new Promise((resolve) => {
            console.log("triggered action -> BILLING_ADDRESS_POST_AREA_NAME_CHANGED")
            console.log("value passed:", value)
            context.commit(actionNames.BILLING_ADDRESS_POST_AREA_NAME_CHANGED, value);
            resolve()
        })
    },
    [actionNames.SHIPPING_ADDRESS_CHANGED]: (context, value) => {
        return new Promise((resolve) => {
            console.log("triggered action -> SHIPPING_ADDRESS_CHANGED")
            console.log("value passed:", value)
            context.commit(mutationNames.SET_SHIPPING_ADDRESS, value);
            resolve()
        })
    },
    [actionNames.SHIPPING_ADDRESS_LINE1_CHANGED]: (context, value) => {
        return new Promise((resolve) => {
            console.log("triggered action -> SHIPPING_ADDRESS_LINE1_CHANGED")
            console.log("value passed:", value)
            context.commit(actionNames.SHIPPING_ADDRESS_LINE1_CHANGED, value);
            resolve()
        })
    },
    [actionNames.SHIPPING_ADDRESS_LINE2_CHANGED]: (context, value) => {
        return new Promise((resolve) => {
            console.log("triggered action -> SHIPPING_ADDRESS_LINE2_CHANGED")
            console.log("value passed:", value)
            context.commit(actionNames.SHIPPING_ADDRESS_LINE2_CHANGED, value);
            resolve()
        })
    },
    [actionNames.SHIPPING_ADDRESS_POST_CODE_CHANGED]: (context, value) => {
        return new Promise((resolve) => {
            console.log("triggered action -> SHIPPING_ADDRESS_POST_CODE_CHANGED")
            console.log("value passed:", value)
            context.commit(actionNames.SHIPPING_ADDRESS_POST_CODE_CHANGED, value);
            resolve()
        })
    },
    [actionNames.SHIPPING_ADDRESS_POST_AREA_NAME_CHANGED]: (context, value) => {
        return new Promise((resolve) => {
            console.log("triggered action -> SHIPPING_ADDRESS_POST_AREA_NAME_CHANGED")
            console.log("value passed:", value)
            context.commit(actionNames.SHIPPING_ADDRESS_POST_AREA_NAME_CHANGED, value);
            resolve()
        })
    },
    [actionNames.SHIPPING_ADDRESS_VALIDATION_STATUS_CHANGED]: (context, value) => {
        return new Promise((resolve) => {
            console.log("triggered action -> SHIPPING_ADDRESS_VALIDATION_STATUS_CHANGED")
            console.log("value passed:", value)
            // value = !context.getters.shippingAddressExists || value
            context.commit(mutationNames.SET_SHIPPING_ADDRESS_VALIDATION_STATUS, value);
            resolve()
        })
    },
    [actionNames.REMOVE_SHIPPING_ADDRESS]: (context) => {
        return new Promise((resolve) => {
            console.log("triggered action -> REMOVE_SHIPPING_ADDRESS")
            context.commit(mutationNames.SET_SHIPPING_ADDRESS, null)
            context.commit(mutationNames.SET_SHIPPING_ADDRESS_VALIDATION_STATUS, true)
            resolve()
        })
    },
    [actionNames.DATE_CHANGED]: (context, value) => {
        return new Promise((resolve) => {
            console.log("triggered action -> DATE_CHANGED")
            console.log("value passed:", value)
            context.commit(actionNames.DATE_CHANGED, value);
            resolve()
        })
    },
    [actionNames.CUSTOMERS_REFERENCE_CHANGED]: (context, value) => {
        return new Promise((resolve) => {
            console.log("triggered action -> CUSTOMERS_REFERENCE_CHANGED")
            console.log("value passed:", value)
            context.commit(actionNames.CUSTOMERS_REFERENCE_CHANGED, value);
            resolve()
        })
    },
    [actionNames.SALES_PRICE_CHANGED]: (context, value) => {
        return new Promise((resolve) => {
            console.log("triggered action -> SALES_PRICE_CHANGED")
            console.log("value passed:", value)
            context.commit(mutationNames.SALES_PRICE_CHANGED, value);
            resolve()
        })
    },
    [actionNames.USER_ID_CHANGED]: (context, value) => {
        return new Promise((resolve) => {
            console.log("triggered action -> USER_ID_CHANGED")
            console.log("value passed:", value)
            context.commit(actionNames.USER_ID_CHANGED, value);
            resolve()
        })
    },
    [actionNames.COMMENT_CHANGED]: (context, value) => {
        return new Promise((resolve) => {
            console.log("triggered action -> COMMENT_CHANGED")
            console.log("value passed:", value)
            context.commit(actionNames.COMMENT_CHANGED, value);
            resolve()
        })
    },
    [actionNames.RESET_STATE]: (context) => {
        return new Promise((resolve) => {
            console.log("triggered action -> RESET_STATE")
            context.commit(actionNames.RESET_STATE);
            resolve()
        })
    },
    [actionNames.FETCH_CUSTOMER]: (context) => {
        return new Promise((resolve, reject) => {
            console.log("triggered action -> FETCH_CUSTOMER")
            var orgNumber = context.getters.getOrgNumber;
            if (!orgNumber) {
                reject();
            }
            axios.get(apiRoutes.customer.getByOrgNumber(orgNumber))
                .then(res => {
                    console.log("Result:", res.data)
                    var customer = res.data
                    if (customer) {
                        context.commit(mutationNames.SET_CUSTOMER_EXISTS_ON_SERVER_STATUS, true)
                        context.commit(mutationNames.UPDATE_CUSTOMER, customer)
                    }
                    resolve()
                })
                .catch(error => {
                    if (error.response && error.response.status === 404) {
                        context.commit(mutationNames.SET_CUSTOMER_EXISTS_ON_SERVER_STATUS, false)
                        resolve()
                    }
                    reject(error)
                })
        })
    },
    [actionNames.NEW_ORDER]: (context, {orgNumber, email}) => {
        return new Promise((resolve) => {
            console.log("triggered action -> NEW_ORDER")
            console.log("orgNumber:", orgNumber, "Email:", email)
            context.dispatch(actionNames.RESET_STATE)
                .then(() => {
                    if (orgNumber) {
                        context.dispatch(actionNames.ORG_NUMBER_CHANGED, orgNumber)
                            .then(() => {
                                context.dispatch(actionNames.FETCH_CUSTOMER)
                                context.dispatch(actionNames.FETCH_COMPANY_LOOKUP_RESULT)
                            })
                    }
                    if (email) {
                        context.dispatch(actionNames.SET_EMAIL, email)
                    }
                    router.push({name: 'newOrder'})
                    resolve()
                })
        })
    },
    [actionNames.SET_EMAIL]: (context, email) => {
        return new Promise((resolve) => {
            console.log("triggered action -> SET_EMAIL")
            context.commit(mutationNames.SET_EMAIL, email)
            resolve()
        })
    },
    [actionNames.FETCH_COMPANY_LOOKUP_RESULT]: (context) => {
        return new Promise((resolve, reject) => {
            console.log("triggered action -> FETCH_COMPANY_LOOKUP_RESULT")
            const orgNumber = context.getters.getOrgNumber
            var emailMessageId = context.getters.getEmailMessageId;
            if(!orgNumber) {
                reject()
            }
            if (emailMessageId) {
                emailMessageId = encodeURIComponent(emailMessageId)
            }
            axios.get(apiRoutes.directoryService.newOrder(orgNumber))
                .then(res => {
                    console.log("Result:", res.data)
                    if (!res.data) {
                        reject()
                    }
                    context.commit(mutationNames.SET_COMPANY_LOOKUP_RESULT, res.data)
                    resolve()
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [actionNames.FETCH_USERS]: (context) => {
        return new Promise((resolve, reject) => {
            console.log("triggered action -> FETCH_USERS")
            axios.get(apiRoutes.user.getAll())
                .then(res => {
                    console.log("Result:", res.data)
                    const users = res.data.users;
                    if (!users) {
                        reject()
                    }
                    context.commit(mutationNames.SET_USERS, users)
                    resolve()
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [actionNames.ADD_ITEM]: (context, item) => {
        return new Promise((resolve) => {
            console.log("triggered action -> ADD_ITEM")
            console.log("Received item:", item)
            context.commit(mutationNames.ADD_ITEM, item)
            resolve()
        })
    },
    [actionNames.UPDATE_ITEM]: (context, { item, index }) => {
        return new Promise((resolve) => {
            console.log("triggered action -> UPDATE_ITEM")
            console.log("Received item:", item, "index:", index)
            context.commit(mutationNames.UPDATE_ITEM, {item, index})
            resolve()
        })
    },
    [actionNames.SUBMIT_CUSTOMER]: (context) => {
        return new Promise((resolve) => {
            console.log("triggered action -> SUBMIT_CUSTOMER")
            if (context.state.customerExistsOnServer) {
                context.dispatch(actionNames.UPDATE_CUSTOMER)
                    .then(() => resolve())
            } else {
                context.dispatch(actionNames.CREATE_CUSTOMER)
                    .then(() => resolve())
            }
        })
    },
    [actionNames.CREATE_CUSTOMER]: (context) => {
        return new Promise((resolve, reject) => {
            console.log("triggered action -> CREATE_CUSTOMER")
            if (!context.getters.customerHasOrders) {
                reject("Tried to create a customer that is already created!")
            }
            var customer = context.state.customer
            axios.post(apiRoutes.customer.create(), customer)
                .then(res => {
                    var customer = res.data
                    context.commit(mutationNames.UPDATE_CUSTOMER, customer)
                    context.commit(mutationNames.SET_CUSTOMER_EXISTS_ON_SERVER_STATUS, true)
                    resolve()
                })
        })
    },
    [actionNames.UPDATE_CUSTOMER]: (context) => {
        return new Promise((resolve, reject) => {
            console.log("triggered action -> UPDATE_CUSTOMER")
            if (!context.state.customerExistsOnServer) {
                reject("Tried to update a customer that does not exist on the server")
                return
            }
            var customer = context.state.customer
            axios.put(apiRoutes.customer.update(customer.id), customer)
                .then(res => {
                    var customer = res.data
                    context.commit(mutationNames.UPDATE_CUSTOMER, customer)
                    resolve()
                })
        })
    },
    [actionNames.SUBMIT_ORDER]: (context) => {
        return new Promise((resolve, reject) => {
            console.log("triggered action -> SUBMIT_ORDER")
            if (!context.getters.customerIsSaved) {
                reject("Can not submit the order until the customer is saved!")
                return
            }
            var order = { ...context.state.order}
            order.customer = {id: context.state.customer.id}
            var notificationId = context.state.email.id
            if (notificationId) {
                order.notificationEmail = {id: notificationId}
            }
            axios.post(apiRoutes.order.create(), order)
                .then(() => context.dispatch(actionNames.ORDER_SUBMITTED))
                .then(() => resolve())
        })
    },
    [actionNames.ORDER_SUBMITTED]: (context) => {
        return new Promise((resolve) => {
            console.log("triggered action -> ORDER_SUBMITTED")
            router.push({name: 'orderEmails'})
                .then(() => context.commit(mutationNames.RESET_STATE))
                .then(() => resolve())
        })
    },
};