
export default {
    customer: {
        getByOrgNumber: (orgNumber) => `/api/v1/customer/org-number=${orgNumber}`,
        create: () => `/api/v1/customer`,
        get: (id) => `/api/v1/customer/${id}`,
        update: (id) => `/api/v1/customer/${id}`,
        getAll: () => `/api/v1/customer`,
    },
    invoice: {
        create: () => '/api/v1/invoice'
    },
    order: {
        create: () => `/api/v1/order`,
        getAll: () => `/api/v1/order`,
        getOne: (orderId) => `/api/v1/order/${orderId}`,
        setFulfillmentStatus: (orderId) => `/api/v1/order/${orderId}/fulfillment-status`,
        setInvoiceSentStatus: (orderId) => `/api/v1/order/${orderId}/invoice-sent`,
        setItemSentByEmailStatus: (orderId) => `/api/v1/order/${orderId}/items-delivered-by-email`,
        setItemSentByPostStatus: (orderId) => `/api/v1/order/${orderId}/items-delivered-by-post`,
    },
    orderComment: {
        create: () => `/order-comment`,
    },
    shipping: {
        createConsignment: () => `/api/v1/consignment`,
        getAll: () => `/api/v1/consignment`,
    },
    email: {
        getInbox: () => `/api/v1/order-notifications/inbox`,
        getOneByMessageId: (messageId) => `/api/v1/order-notifications/inbox/${messageId}`,
        getInboxCount: () => `/api/v1/order-notifications/count/inbox`,
        setIsProcessed: (messageId) => `/api/v1/order-notifications/inbox/${messageId}/is-processed`,
    },
    directoryService: {
        newOrder: (orgNumber) => `/api/v1/directory-service/basic-company-info/${orgNumber}`,
    },
    user: {
        getAll: () => `/api/v1/user`,
        getSelf: () => `/api/v1/user/self`,
    },
    leads: {
        downloadCustomersByLatestOrderDate: () => `/api/v1/leads/customers-by-latest-order-date`
    }
}