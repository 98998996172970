import getDefaultState from './state'
import rfdc from 'rfdc'
const clone = rfdc()
// import Vue from 'vue'

const namespace = "NEW_ORDER__";

const mutationNames = {
    ORG_NUMBER_CHANGED: namespace + "ORG_NUMBER_CHANGED",
    CUSTOMER_NAME_CHANGED: namespace + "CUSTOMER_NAME_CHANGED",
    SHIPPING_EMAIL_CHANGED: namespace + "SHIPPING_EMAIL_CHANGED",
    BILLING_EMAIL_CHANGED: namespace + "BILLING_EMAIL_CHANGED",
    SHIPPING_ADDRESS_LINE1_CHANGED: namespace + "SHIPPING_ADDRESS_LINE1_CHANGED",
    SHIPPING_ADDRESS_LINE2_CHANGED: namespace + "SHIPPING_ADDRESS_LINE2_CHANGED",
    SHIPPING_ADDRESS_POST_AREA_NAME_CHANGED: namespace + "SHIPPING_ADDRESS_POST_AREA_NAME_CHANGED",
    SHIPPING_ADDRESS_POST_CODE_CHANGED: namespace + "SHIPPING_ADDRESS_POST_CODE_CHANGED",
    SET_BILLING_ADDRESS: namespace + "SET_BILLING_ADDRESS",
    BILLING_ADDRESS_LINE1_CHANGED: namespace + "BILLING_ADDRESS_LINE1_CHANGED",
    BILLING_ADDRESS_LINE2_CHANGED: namespace + "BILLING_ADDRESS_LINE2_CHANGED",
    BILLING_ADDRESS_POST_AREA_NAME_CHANGED: namespace + "BILLING_ADDRESS_POST_AREA_NAME_CHANGED",
    BILLING_ADDRESS_POST_CODE_CHANGED: namespace + "BILLING_ADDRESS_POST_CODE_CHANGED",
    DATE_CHANGED: namespace + "DATE_CHANGED",
    CUSTOMERS_REFERENCE_CHANGED: namespace + "CUSTOMERS_REFERENCE_CHANGED",
    USER_ID_CHANGED: namespace + "USER_ID_CHANGED",
    COMMENT_CHANGED: namespace + "COMMENT_CHANGED",
    RESET_STATE: namespace + "RESET_STATE",
    FETCH_CUSTOMER: namespace + "FETCH_CUSTOMER",
    NEW_ORDER: namespace + "NEW_ORDER",
    SET_EMAIL: namespace + "SET_EMAIL",
    SET_USERS: namespace + "SET_USERS",
    SET_COMPANY_LOOKUP_RESULT: namespace + "SET_COMPANY_LOOKUP_RESULT",
    ADD_ITEM: namespace + "ADD_ITEM",
    UPDATE_CUSTOMER: namespace + "UPDATE_CUSTOMER",
    SALES_PRICE_CHANGED: namespace + "SALES_PRICE_CHANGED",
    CUSTOMER_PHONE_NUMBER_CHANGED: namespace + "CUSTOMER_PHONE_NUMBER_CHANGED",
    CUSTOMER_NOTE_CHANGED: namespace + "CUSTOMER_NOTE_CHANGED",
    UPDATE_ITEM: namespace + "UPDATE_ITEM",
    DELETE_ITEM: namespace + "DELETE_ITEM",
    SET_SHIPPING_ADDRESS: namespace + "SET_SHIPPING_ADDRESS",
    CREATE_SHIPPING_ADDRESS: namespace + "CREATE_SHIPPING_ADDRESS",
    SET_ORDER_ITEM_SAVED_STATUS: namespace + "SET_ORDER_ITEM_SAVED_STATUS",
    SET_ORDER_ITEM_POSTAL_DELIVERY: namespace + "SET_ORDER_ITEM_POSTAL_DELIVERY",
    SET_ORDER_ITEM_EMAIL_DELIVERY: namespace + "SET_ORDER_ITEM_EMAIL_DELIVERY",
    SET_ORDER_ITEM_DISPLAY_NAME: namespace + "SET_ORDER_ITEM_DISPLAY_NAME",
    SET_ORDER_ITEM_DISPLAY_TITLE: namespace + "SET_ORDER_ITEM_DISPLAY_TITLE",
    SET_ORDER_ITEM_YEARS_RUNNING: namespace + "SET_ORDER_ITEM_YEARS_RUNNING",
    SET_ORDER_ITEM_ELITEMERKET: namespace + "SET_ORDER_ITEM_ELITEMERKET",
    SET_ORDER_ITEM_LANGUAGE: namespace + "SET_ORDER_ITEM_LANGUAGE",
    SET_EDITING_ORDER_ITEM: namespace + "SET_EDITING_ORDER_ITEM",
    SET_ACTIVE_TAB: namespace + "SET_ACTIVE_TAB",
    SET_BILLING_ADDRESS_VALIDATION_STATUS: namespace + "SET_BILLING_ADDRESS_VALIDATION_STATUS",
    SET_SHIPPING_ADDRESS_VALIDATION_STATUS: namespace + "SET_SHIPPING_ADDRESS_VALIDATION_STATUS",
    SET_ORDER_TAB_VALIDATION_STATUS: namespace + "SET_ORDER_TAB_VALIDATION_STATUS",
    SET_CUSTOMER_EXISTS_ON_SERVER_STATUS: namespace + "SET_CUSTOMER_EXISTS_ON_SERVER_STATUS",
}

export const mutations = {
    [mutationNames.ORG_NUMBER_CHANGED]: (state, value) => {
        state.customer.orgNumber = value;
        console.log("Customer changed. New state:", state)
    },
    [mutationNames.CUSTOMER_NAME_CHANGED]: (state, value) => {
        state.customer.name = value;
        console.log("Customer changed. New state:", state)
    },
    [mutationNames.CUSTOMER_PHONE_NUMBER_CHANGED]: (state, value) => {
        state.customer.phoneNumber = value;
        console.log("Customer changed. New state:", state)
    },
    [mutationNames.CUSTOMER_NOTE_CHANGED]: (state, value) => {
        state.customer.note = value;
        console.log("Customer changed. New state:", state)
    },
    [mutationNames.SHIPPING_EMAIL_CHANGED]: (state, value) => {
        state.customer.shippingEmail = value;
        console.log("Customer changed. New state:", state)
    },
    [mutationNames.BILLING_EMAIL_CHANGED]: (state, value) => {
        state.customer.billingEmail = value;
        console.log("Customer changed. New state:", state)
    },
    [mutationNames.SET_BILLING_ADDRESS]: (state, value) => {
        state.customer.billingAddress = clone(value);
        console.log("Customer changed. New state:", state)
    },
    [mutationNames.BILLING_ADDRESS_LINE1_CHANGED]: (state, value) => {
        state.customer.billingAddress.line1 = value;
        console.log("Customer changed. New state:", state)
    },
    [mutationNames.BILLING_ADDRESS_LINE2_CHANGED]: (state, value) => {
        state.customer.billingAddress.line2 = value;
        console.log("Customer changed. New state:", state)
    },
    [mutationNames.BILLING_ADDRESS_POST_CODE_CHANGED]: (state, value) => {
        state.customer.billingAddress.postCode = value;
        console.log("Customer changed. New state:", state)
    },
    [mutationNames.BILLING_ADDRESS_POST_AREA_NAME_CHANGED]: (state, value) => {
        state.customer.billingAddress.postAreaName = value;
        console.log("Customer changed. New state:", state)
    },
    [mutationNames.SHIPPING_ADDRESS_LINE1_CHANGED]: (state, value) => {
        state.customer.shippingAddress.line1 = value;
        console.log("Customer changed. New state:", state)
    },
    [mutationNames.SHIPPING_ADDRESS_LINE2_CHANGED]: (state, value) => {
        state.customer.shippingAddress.line2 = value;
        console.log("Customer changed. New state:", state)
    },
    [mutationNames.SHIPPING_ADDRESS_POST_CODE_CHANGED]: (state, value) => {
        state.customer.shippingAddress.postCode = value;
        console.log("Customer changed. New state:", state)
    },
    [mutationNames.SHIPPING_ADDRESS_POST_AREA_NAME_CHANGED]: (state, value) => {
        state.customer.shippingAddress.postAreaName = value;
        console.log("Customer changed. New state:", state)
    },
    [mutationNames.SET_BILLING_ADDRESS_VALIDATION_STATUS]: (state, value) => {
        state.validation.billingAddressIsValid = value;
        console.log("Billing address validation state set to:", value)
    },
    [mutationNames.SET_SHIPPING_ADDRESS_VALIDATION_STATUS]: (state, value) => {

        state.validation.shippingAddressIsValid = value;
        console.log("Shipping address validation state set to:", value)
    },
    [mutationNames.SET_SHIPPING_ADDRESS]: (state, address) => {
        state.customer.shippingAddress = clone(address);
        console.log("Customer changed. New state:", state)
    },
    [mutationNames.CREATE_SHIPPING_ADDRESS]: (state, address) => {
        if (address) {
            state.customer.shippingAddress = clone(address);
        } else {
            state.customer.shippingAddress = {
                postCode: state.customer.billingAddress.postCode,
                postAreaName: state.customer.billingAddress.postAreaName
            }
        }
        console.log("Customer changed. New state:", state)
    },
    [mutationNames.DATE_CHANGED]: (state, value) => {
        state.order.date = value;
        console.log("Order details changed. New state:", state)
    },
    [mutationNames.CUSTOMERS_REFERENCE_CHANGED]: (state, value) => {
        state.order.customersReference = value;
        console.log("Order details changed. New state:", state)
    },
    [mutationNames.SALES_PRICE_CHANGED]: (state, value) => {
        state.order.salesPrice = value;
        console.log("Order details changed. New state:", state)
    },
    [mutationNames.USER_ID_CHANGED]: (state, value) => {
        state.order.userId = value;
        console.log("Order details changed. New state:", state)
    },
    [mutationNames.COMMENT_CHANGED]: (state, value) => {
        state.order.comment = value;
        console.log("Order details changed. New state:", state)
    },
    [mutationNames.SET_ORDER_TAB_VALIDATION_STATUS]: (state, value) => {
        state.validation.orderTabIsValid = value;
        console.log("Order tab validation status set to:", value)
    },
    [mutationNames.RESET_STATE]: (state) => {
        Object.assign(state, getDefaultState());
        console.log("State was reset. New state:", state)
    },
    [mutationNames.SET_EMAIL]: (state, email) => {
        state.email = email;
        console.log("Email was replaced. New state:", state)
    },
    [mutationNames.SET_USERS]: (state, users) => {
        state.users = users;
        console.log("User list was replaced. New state:", state)
    },
    [mutationNames.SET_COMPANY_LOOKUP_RESULT]: (state, companyLookup) => {
        state.companyLookup = companyLookup;
        console.log("Autofill suggestions were replaced. New state:", state)
    },
    [mutationNames.ADD_ITEM]: (state, item) => {
        state.order.items.push(item);
        console.log("An order item was added. New state:", state)
    },
    [mutationNames.UPDATE_ITEM]: (state, {item, index}) => {
        console.log("Updating item:", item, index)
        state.order.items[index] = item;
        console.log("An order item was updated. New state:", state)
    },
    [mutationNames.DELETE_ITEM]: (state, index) => {
        state.order.items.splice(index, 1);
        console.log("An order item was removed. New state:", state)
    },
    [mutationNames.SET_ORDER_ITEM_SAVED_STATUS]: (state, {index, value}) => {
        console.log("Setting saved status:", index, value)
        var newItem = {
            ...state.order.items[index],
            $saved: value,
        }
        // Vue.set(state.order.items, index, newItem);
        state.order.items[index] = newItem;
        console.log("An order item was updated. New state:", state)
    },
    [mutationNames.SET_ORDER_ITEM_POSTAL_DELIVERY]: (state, {index, value}) => {
        console.log("Setting postal delivery:", index, value)
        var newItem = {
            ...state.order.items[index],
            postalDeliveryChosen: value,
        }
        // Vue.set(state.order.items, index, newItem);
        state.order.items[index] = newItem;
        console.log("An order item was updated. New state:", state)
    },
    [mutationNames.SET_ORDER_ITEM_EMAIL_DELIVERY]: (state, {index, value}) => {
        console.log("Setting email delivery:", index, value)
        var newItem = {
            ...state.order.items[index],
            emailDeliveryChosen: value,
        }
        // Vue.set(state.order.items, index, newItem);
        state.order.items[index] = newItem
        console.log("An order item was updated. New state:", state)
    },
    [mutationNames.SET_ORDER_ITEM_DISPLAY_NAME]: (state, {index, value}) => {
        console.log("Setting display name:", index, value)
        var newItem = {
            ...state.order.items[index],
            displayName: value,
        }
        // Vue.set(state.order.items, index, newItem);
        state.order.items[index] = newItem
        console.log("An order item was updated. New state:", state)
    },
    [mutationNames.SET_ORDER_ITEM_DISPLAY_TITLE]: (state, {index, value}) => {
        console.log("Setting display title:", index, value)
        var newItem = {
            ...state.order.items[index],
            displayTitle: value,
        }
        // Vue.set(state.order.items, index, newItem);
        state.order.items[index] = newItem
        console.log("An order item was updated. New state:", state)
    },
    [mutationNames.SET_ORDER_ITEM_YEARS_RUNNING]: (state, {index, value}) => {
        console.log("Setting years running:", index, value)
        var newItem = {
            ...state.order.items[index],
            yearsRunning: value,
        }
        // Vue.set(state.order.items, index, newItem);
        state.order.items[index] = newItem
        console.log("An order item was updated. New state:", state)
    },
    [mutationNames.SET_ORDER_ITEM_ELITEMERKET]: (state, {index, value}) => {
        console.log("Setting elitemerket:", index, value)
        var newItem = {
            ...state.order.items[index],
            elitemerket: value,
        }
        // Vue.set(state.order.items, index, newItem);
        state.order.items[index] = newItem
        console.log("An order item was updated. New state:", state)
    },
    [mutationNames.SET_ORDER_ITEM_LANGUAGE]: (state, {index, value}) => {
        console.log("Setting language:", index, value)
        var newItem = {
            ...state.order.items[index],
            language: value,
        }
        // Vue.set(state.order.items, index, newItem);
        state.order.items[index] = newItem
        console.log("An order item was updated. New state:", state)
    },
    [mutationNames.SET_EDITING_ORDER_ITEM]: (state, value) => {
        console.log("Mutation SET_EDITING_ORDER_ITEM:", value)
        state.validation.editingAnOrderItem = value
    },
    [mutationNames.UPDATE_CUSTOMER]: (state, customer) => {
        if (customer) {
            var customerClone1 = clone(customer)
            console.log("Customer clone:", customerClone1)
            state.customerOnServer = clone(customer)
            state.customer = clone(customer)
            console.log("The customer was replaced. New state:", state)
        }
    },
    [mutationNames.SET_CUSTOMER_EXISTS_ON_SERVER_STATUS]: (state, value) => {
        state.customerExistsOnServer = value
        console.log("Mutation SET_CUSTOMER_EXISTS_ON_SERVER_STATUS. Value:", value, 'New state:', state)
    },
    [mutationNames.SET_ACTIVE_TAB]: (state, value) => {
        state.activeTab = value
        console.log("The active tab was set to:", value)
    },
};

export default mutationNames;
