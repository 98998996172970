// import equal from "deep-equal";

const namespace = "ORDER_FULFILLMENT__";


const getterNames = {
    ALL_DELIVERABLES_ARE_FULFILLED: namespace + "ALL_DELIVERABLES_ARE_FULFILLED",
    ANY_ITEMS_SELECTED: namespace + "ANY_ITEMS_SELECTED",
}

export const getters = {
    [getterNames.ALL_DELIVERABLES_ARE_FULFILLED]: state => {
        if (!state.currentOrder.items) return undefined
        var orrderItemsAreFulfilled = state.currentOrder.items.every(item => {
            var isDeliveredByPost = !item.canBeDeliveredByPost
                || !item.postalDeliveryChosen
                || item.isDeliveredByPost
            var isDeliveredByEmail = !item.canBeDeliveredByEmail
                || !item.emailDeliveryChosen
                || item.isDeliveredByEmail

            return isDeliveredByPost && isDeliveredByEmail
        })

        var invoiceIsSent = state.currentOrder.invoiceIsSent

        return orrderItemsAreFulfilled && invoiceIsSent
    },
    [getterNames.ANY_ITEMS_SELECTED]: state => {
        var mask = state.orderItemSelectionMask
        return mask.some(selected => selected)
    },
};

export default getterNames