<template>
<nav class="navbar" v-if="isAuthenticated">
  <div class="mobile-menu-bar" v-if="showHamburgerMenu">
    <span class="menu-icon" @click="hamburgerMenuExtended = !hamburgerMenuExtended">
      <i v-if="!hamburgerMenuExtended" class="mdi mdi-24px mdi-menu"></i>
      <i v-else class="mdi mdi-24px mdi-close"></i>
    </span>
  </div>
  <div class="main-menu" v-if="showMainMenu">
    <div class="navbar-content">
      <div v-if="!showHamburgerMenu" class="logo-wrapper">
        <SiteLogoStacked />
      </div>

      <div class="nav-links">
        <router-link
            v-for="(link, index) in navLinks"
            :key="index"
            :to="{name: link.routeName}">
          <span @click="hamburgerMenuExtended = false">
            <NavLink
                v-bind="link"
                :is-active="currentRouteName === link.routeName"
            />
          </span>
        </router-link>
      </div>
    </div>
    <hr class="divider" />
    <div class="navbar-content">

      <!--<span>{{$store.state.user.user.firstName}}</span>-->
      <a href="#" class="logout-link" @click="logout">
        <i class="mdi mdi-24px mdi-logout"></i>
        <span class="name">
        Logg ut
      </span>
      </a>
    </div>
  </div>
</nav>
</template>

<script>

import NavLink from "@/components/Ui/NavLink.vue";
import { routes } from "@/router"
import SiteLogoStacked from "@/components/Ui/SiteLogoStacked.vue";
import { breakpoints } from "@/constants";

export default {
    name: "MainMenu",
    components: {
      NavLink,
      SiteLogoStacked,
    },
    data() {
      return {
        hamburgerMenuExtended: false,
        viewportWidth: window.innerWidth,
        viewportHeight: window.innerHeight,
        navLinks: [
          {
            text: "Dashbord",
            iconClass: "mdi-view-dashboard",
            routeName: routes.home
          },
          {
            text: "Kunder",
            iconClass: "mdi-account-box-multiple",
            routeName: routes.customerList
          },
          {
            text: "Salgsmail",
            iconClass: "mdi-email",
            routeName: routes.orderEmails
          },
          {
            text: "Ny ordre",
            iconClass: "mdi-bell-plus",
            routeName: routes.newOrder
          },
          {
            text: "Ordreliste",
            iconClass: "mdi-account-box-multiple",
            routeName: routes.orderListPage
          },
          {
            text: "Ordrerapport",
            iconClass: "mdi-file-document-multiple",
            routeName: routes.orderReportPage
          },
          {
            text: "Leads",
            iconClass: "mdi-file-document-multiple",
            routeName: routes.leadsPage
          },
          {
            text: "Ordrebehandling",
            iconClass: "mdi-truck",
            routeName: routes.orderFulfillmentPage
          },
        ]
      }
    },
    mounted() {
      window.addEventListener('resize', () => {
        this.viewportWidth = window.innerWidth;
        this.viewportHeight = window.innerHeight;
      });
    },
    computed: {
      isAuthenticated () {
        return this.$store.getters.isAuthenticated
      },
      currentRouteName() {
        return this.$route.name;
      },
      showHamburgerMenu() {
        return this.isAuthenticated && this.viewportWidth < breakpoints.mobile;
      },
      showMainMenu() {
        return this.isAuthenticated && (!this.showHamburgerMenu || this.hamburgerMenuExtended)
      }
    },
    methods: {
      logout() {
        this.hamburgerMenuExtended = false
        this.$store.dispatch("AUTH_LOGOUT")
      }
    }
}
</script>

<style scoped lang="scss">
@use '@/styles/variables/colors';
@use '@/styles/variables/_layout.scss';

.mobile-menu-bar {
  display: flex;
  background: colors.$main-menu-background;
  width: 100%;
  .menu-icon {
    color: colors.$main-menu-text;
    padding: 0.25em 1em;
  }
}

.navbar {
  position: sticky;
  overflow-y: auto;
  top: 0;
  height: 100vh;
  min-width: 280px;
  width: 280px;

  background-color: colors.$main-menu-background;
}

.logo-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 2.5rem;
  margin-bottom: 2em;
}

.divider {
  border-top: 1px solid #055864;
  margin: 1em 0;
}

.nav-links {
  display: flex;
  flex-direction: column;
}

.logout-link {
  display: flex;
  align-items: center;
  color: colors.$main-menu-text;
  font-family: 'Neue Haas Grotesk Display Pro 65 Medium', sans-serif;
  text-decoration: none;

  padding: .5em 1.5em;
  margin: 0 0.75em;
  font-size: 1rem;

  .name {
    margin-left: 0.75em;
  }

  &:hover {
    color: lighten(colors.$main-menu-text, 7%);
  }
}

@media print {
  .navbar {
    display: none;
  }
}

@media (max-width: layout.$breakpoint-hamburger-menu) {
  .navbar {
    height: unset;
    position: relative;
    width: 100%;
  }
}
</style>