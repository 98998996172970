import Vuex from "vuex";
import user from "./modules/user";
import auth from "./auth";
import newOrder from "./newOrder";
import orderList from './orderList'
import orderFulfillment from './orderFulfillment'
import VuexPersister from "vuex-persister"

const debug = import.meta.env.NODE_ENV !== "production";

const vuexPersister = new VuexPersister({
  // ...your options
})

export default Vuex.createStore({
  modules: {
    user,
    auth,
    newOrder,
    orderList,
    orderFulfillment,
  },
  plugins: [
    vuexPersister.persist
  ],
  strict: debug,
});
