import { EventEmitter } from 'events'

class ModalService {
    _emitter = new EventEmitter()

    onShowModal(callback) {
        this._emitter.addListener('show-modal', callback)
    }

    showModal(showModalEvent) {
        return new Promise((resolve, reject) => {
            this._emitter.emit('show-modal',  {
                ...showModalEvent,
                $context: new ModalContext(resolve, reject) })
        })
    }
}

class ModalContext {
    _emitter = new EventEmitter()

    constructor(resolve, reject) {
        this.resolve = resolve
        this.reject = reject
        this.onClose((closeEvent) => {
            resolve(closeEvent)
        })
    }

    onClose(callback) {
        this._emitter.addListener('close', callback)
    }

    close(closeEvent) {
        this._emitter.emit('close', closeEvent)
    }
}

export default new ModalService()