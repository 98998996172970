import getState from "./state";
import {getters} from "./getters";
import {actions} from "./actions";
import {mutations} from "./mutations";

const state = function() {
    return getState()
}
export default {
    state,
    getters,
    actions,
    mutations
};

