<template>
  <div class="panel" >
    <slot></slot>
  </div>
</template>

<script>

const panelColors = [
    'white'
]
export default {
  name: 'app-panel',
  props:{
    color: { type: String, default: 'white', validator: value => panelColors.includes(value) },
  },
}
</script>

<style scoped lang="scss">
  @use '@/styles/variables/_layout.scss';

  .panel {
    background-color: white;
    border-radius: 6px;
  }
</style>