import equal from "deep-equal";

export const getters = {
    getOrgNumber: state => {
        try {
            console.log("Fetching orgNumber from store")
            return state.customer.orgNumber;
        } catch {
            console.log("Failed to retrieve orgNumber from store!")
        }
    },
    getEmailMessageId: state => {
        return state.email.messageId;
    },
    customerHasOrders: state => {
        return state.customer.orders.length === 0;
    },
    customerIsSaved: state => {
        return equal(state.customer, state.customerOnServer, {strict: false});
    },
    shippingAddressExists: state => {
        return state.customer.shippingAddress != null;
    },
};