
export const getters = {
    isAuthenticated: state => {
        // console.log("isAuthenticated called. Result:", !!state.token)
        return !!state.token
    },
    getToken: state => {
        try {
            // console.log("Fetching token from store:", state.token)
            return state.token
        } catch {
            console.log("Failed to retrieve token from store!")
        }
    },
    getRefreshToken: state => {
        try {
            // console.log("Fetching refresh token from store", state.refreshToken)
            return state.refreshToken
        } catch {
            console.log("Failed to retrieve token from store!")
        }
    },
    tokenIsExpired: state => {
        try {
            console.log("Fetching token expiration from store")
            const token = state.token;
            const claimsEncoded = token.split('.')[1];
            const claims = JSON.parse(atob(claimsEncoded))
            const currentEpochTimeSeconds = Date.now()/ 1000;
            const tokenIsExpired = claims.exp < currentEpochTimeSeconds;
            return tokenIsExpired;
        } catch {
            console.log("Failed to retrieve token from store!")
        }
    }
};