import store from './store/'
import { AUTH_REFRESH_TOKEN_REQUEST } from './store/auth/actions'

export const echoOutgoingRequests = function(config) {
    // Do something before request is sent
    console.log("---->Outgoing request from Axios", config)
    return new Promise(resolve => resolve(config))
}

export const handleEchoOutgoingRequestsError = function(error) {
    // Do something with request error
    return Promise.reject(error)
  }

export const echoIncomingResponses = function (response) {
    console.log("<----Incoming response to Axios", response)
    if (response.data.errors) {
        console.log(response.data.errors)
    }
    return Promise.resolve(response)
  }

export const silentlyRefreshTokens = function(config) {
    return new Promise(function(resolve) {
        if (store.getters.tokenIsExpired) {
            store.dispatch(AUTH_REFRESH_TOKEN_REQUEST)
                .then(() => resolve(config))
                .catch(err => echoErrorResponse(err))
        } else {
            resolve(config)
        }
    })
}

export const addTokenToOutgoingRequests = function(config) {
    return new Promise(function(resolve) {
        const token = store.getters.getToken;
        if (token) {
            console.log("Added auth token to outgoing request")
            config.headers.common['Authorization'] = `Bearer ${token}`
            resolve(config)
        } else {
            console.log("No token to add to the outgoing request")
            resolve(config)
        }
    })
}

export const echoErrorResponse = function(error) {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(`Error on outgoing request. The server responded ${error.response.status}: ${error.response.data.errors}\nFull response:`, error.response);
        } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(`Error on outgoing request. The server did not respond. The request was `, error.request);
        } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error while preparing an outgoing request:', error.message, '\n', error);
        }
        console.log("error.config:", error.config);
    throw error;
}

// export async function redirectWhenUnauthorized (error) {
//   if (error.status === 401 && error.config && !error.config.__isRetryRequest) {
//       // if you ever get an unauthorized, logout the user
//       this.$store.dispatch(AUTH_LOGOUT)
//       // you can also redirect to /login if needed !
//   }
//   throw error;
// }
