import axios from "axios";
import router from "../../router";

export const AUTH_SIGNIN_REQUEST = "AUTH_SIGNIN_REQUEST";
export const AUTH_REFRESH_TOKEN_REQUEST = "AUTH_REFRESH_TOKEN_REQUEST";
export const AUTH_ACQUIRED_TOKENS = "AUTH_ACQUIRED_TOKENS";
export const AUTH_SIGNIN_SUCCESS = "AUTH_SIGNIN_SUCCESS";
export const AUTH_SIGNIN_ERROR = "AUTH_SIGNIN_ERROR";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

import { actionNames as userActions}  from "../modules/user";

const actionNames = {
    AUTH_SIGNIN_REQUEST: "AUTH_SIGNIN_REQUEST",
    AUTH_REFRESH_TOKEN_REQUEST: "AUTH_REFRESH_TOKEN_REQUEST",
    AUTH_ACQUIRED_TOKENS: "AUTH_ACQUIRED_TOKENS",
    AUTH_SIGNIN_ERROR: "AUTH_SIGNIN_ERROR",
    AUTH_LOGOUT: "AUTH_LOGOUT",
}
export default actionNames

export const actions = {
    [AUTH_SIGNIN_REQUEST]: (context, {username, password}) => {
        // console.log("Triggered AUTH_SIGNIN_REQUEST with: ", username, password)
        return new Promise((resolve, reject) => {
            axios.post("/api/v1/identity/login", {username, password})
                .then(res => {
                    console.log("signin action received response:", res.data)
                    context.dispatch(AUTH_ACQUIRED_TOKENS, res.data)
                    router.push("/")
                        .then(() => console.log("Navigation success!"))
                        .catch(() => console.log("Navigation failure"))
                    resolve(res)
                })
                .then(() => context.dispatch(userActions.ON_USER_LOGGED_IN))
                .catch(err => {
                    context.commit(AUTH_SIGNIN_ERROR);
                    reject(err)
                })
        })
    },
    [AUTH_REFRESH_TOKEN_REQUEST]: async ({ dispatch, getters }) => {
        console.log("Our token is expired, requesting a new one ...")
        const token = getters.getToken;
        const refreshToken = getters.getRefreshToken;
        if(!refreshToken) {
            dispatch(AUTH_LOGOUT)
        }

        const newAxiosInstance = axios.create();
        try {
            const result = await newAxiosInstance.post("/api/v1/identity/refresh", {token, refreshToken})
            return dispatch(AUTH_ACQUIRED_TOKENS, result.data)
        } catch (error) {
            console.log(error)
            if (error.response
                && error.response.status === 400
            ) {
                if (error.response.data.errors.includes('This refresh token has expired')
                    || error.response.data.errors.includes('This refresh token has been used')
                ) {
                    return dispatch(AUTH_LOGOUT)
                }
            }
            if (error.response
                && error.response.status === 401
            ) {
                return dispatch(AUTH_LOGOUT)
            }
        }
    },
    [AUTH_ACQUIRED_TOKENS]: (context, {token, refreshToken}) => {
        return new Promise(function(resolve) {
            if (!token) {
                throw new Error("AUTH_ACQUIRED_TOKENS action called, but the token was not passed")
            }
            if (!refreshToken) {
                throw new Error("AUTH_ACQUIRED_TOKENS action called, but the refresh token was not passed")
            }
            context.commit(AUTH_ACQUIRED_TOKENS, {token, refreshToken})
            resolve()
        })
    },
    [AUTH_LOGOUT]: ({ commit }) => {
        return new Promise(resolve => {
            commit(AUTH_LOGOUT);
            router.push("/login")
                .then(() => console.log("Navigation success!"))
                .catch(() => console.log("Navigation failure"))
            resolve();
        });
    }
};