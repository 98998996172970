import { createApp } from 'vue';
import App from './App.vue'
import router from './router'
import store from './store/'
import axios from 'axios'
import {
  echoOutgoingRequests, 
  echoIncomingResponses,
  addTokenToOutgoingRequests,
  echoErrorResponse,
  silentlyRefreshTokens,
} from './httpFilters'
import clickedOutsideDirective from "@/Directives/clickedOutsideDirective";
import './styles/main.scss'

axios.interceptors.request.use(config => 
  {
    return new Promise(function(resolve, reject){
      silentlyRefreshTokens(config)
        .then(config => addTokenToOutgoingRequests(config))
        .then(config => echoOutgoingRequests(config))
        .then(config => resolve(config))
        .catch(error => reject(error))
    })
  },
  echoErrorResponse
)
// axios.interceptors.request.use(addTokenToOutgoingRequests)
// axios.interceptors.request.use(echoOutgoingRequests)

axios.interceptors.response.use(echoIncomingResponses, echoErrorResponse);

createApp(App)
    .use(router)
    .use(store)
    .directive('clicked-outside', clickedOutsideDirective)
    .mount('#app')
 