import getState from './state'
import rfdc from 'rfdc'
const clone = rfdc()
// import Vue from 'vue'

const namespace = "ORDER_FULFILLMENT__";


const mutationNames = {
    SET_ORDER_LIST: namespace + "SET_ORDER_LIST",
    SET_CURRENT_ORDER: namespace + "SET_CURRENT_ORDER",
    SET_SELECTED_STATUS_ALL: namespace + "SET_SELECTED_STATUS_ALL",
    TOGGLE_ORDER_ITEM_SELECTED: namespace + "TOGGLE_ORDER_ITEM_SELECTED",
    RESET_STATE: namespace + "RESET_STATE",
    RESET_ORDER_ITEM_SELECTION: namespace + "RESET_ORDER_ITEM_SELECTION",
}

export const mutations = {
    [mutationNames.SET_ORDER_LIST]: (state, orders) => {
        console.log("Mutation triggered --> SET_ORDER_LIST, value:", orders)
        state.orders = clone(orders)
    },
    [mutationNames.SET_CURRENT_ORDER]: (state, order) => {
        console.log("Mutation triggered --> SET_CURRENT_ORDER, value:", order)
        state.currentOrder = clone(order)
    },
    [mutationNames.RESET_ORDER_ITEM_SELECTION]: (state) => {
        var order = state.currentOrder
        console.log("Mutation triggered --> RESET_ORDER_ITEM_SELECTION, value:", order)
        if (order.items.length === 1) {
            state.orderItemSelectionMask = new Array(order.items.length).fill(true)
        } else {
            state.orderItemSelectionMask = new Array(order.items.length).fill(false)
        }
    },
    [mutationNames.TOGGLE_ORDER_ITEM_SELECTED]: (state, orderItemIndex) => {
        console.log("Mutation triggered --> TOGGLE_ORDER_ITEM_SELECTED, index:", orderItemIndex)
        var mask = clone(state.orderItemSelectionMask)
        mask[orderItemIndex] = !mask[orderItemIndex]
        state.orderItemSelectionMask = mask
    },
    [mutationNames.SET_SELECTED_STATUS_ALL]: (state, value) => {
        console.log("Mutation triggered --> SET_SELECTED_STATUS_ALL, value:", value)
        var mask = new Array(state.currentOrder.items.length).fill(value)
        state.orderItemSelectionMask = mask
    },
    [mutationNames.RESET_STATE]: (state) => {
        Object.assign(state, getState())
    },
};

export default mutationNames;
