export default function() {
    return {
        customerOnServer: undefined,
        customerExistsOnServer: undefined,
        customer: {
            id: 0,
            orgNumber: null,
            name : null,
            phoneNumber: null,
            shippingEmail: null,
            billingEmail: null,
            billingAddress: {
                line1: null,
                line2: null,
                postCode: null,
                postAreaName: null
            },
            shippingAddress: null,
            orders: [],
        },
        validation: {
            billingAddressIsValid: false,
            shippingAddressIsValid: true,
            orderTabIsValid: false,
            editingAnOrderItem: false,
        },
        order: {
            date: null,
            customersReference : '',
            salesPrice: null,
            userId: '',
            comments: [],
            note: '',
            items: [],
        },
        status: "",
        users: [],
        email: {
            messageId: "",
            senderName: "",
            senderAddress: "",
            subject: "",
            body: "",
            date: "",
        },
        companyLookup: {
            "orgNumber": null,
            "name": null,
            "businessAddress": null,
            "postalAddress": null,
            "statutoryPurpose": null,
            "industryName": null,
            "industryCode": null,
            "sectorCode": null,
            "latestIncomeStatementYear": null,
            "organizationTypeCode": null,
            "organizationTypeName": null,
            "municipality": null,
            "registeredDate": null,
            "incorporatedDate": null,
            "ceo": null,
            "chairman": null,
            "soleProprietor": null,
            "procurator": null,
            "employeeCount": null,
            "webAddress": null,
            "underBankruptcy": false,
            "underLiquidation": false,
            "underForcedLiquidation": false,
            "brregLink": null
        },
        activeTab: "customer",
    }
}