<template>
  <button
      class="button"
      :class="[type, { 'disabled': disabled }]"
      @click.prevent="$emit('click')"
      :disabled="disabled"
  >
    <span class="icon-wrapper" :class="{'left': hasText}" v-if="hasIcon && iconPosition === 'left'">
      <slot name="icon" >
        <i  v-if="iconClass"  class="mdi" :class="iconClass"></i>
      </slot>
    </span>

    <slot v-if="hasText"><span>{{ text }}</span></slot>

    <span class="icon-wrapper" :class="{'right': hasText}" v-if="hasIcon && iconPosition === 'right'">
      <slot name="icon">
        <i  v-if="iconClass"  class="mdi" :class="iconClass"></i>
      </slot>
    </span>
  </button>
</template>

<script>
const buttonTypes =  [
  "primary",
  "secondary",
  "light",
  "clear"
]

const iconPositions = [
    "left",
    "right"
]

export default {
name: "AppButton",
  emits: [ "click" ],
  props: {
    text: { String },
    type: {
      type: String,
      default: "primary",
      validator: (value) => buttonTypes.includes(value),
    },
    disabled: { Boolean, default: false },
    iconClass: { type: String },
    iconPosition: {
      type: String,
      default: "left",
      validator: (value) => iconPositions.includes(value)
    },
  },
  computed: {
    hasIcon() {
      const hasIconClass = this.iconClass !== undefined
      const hasIconSlotContent = this.$slots.icon !== undefined
      return hasIconClass || hasIconSlotContent
    },
    hasText() {
      const hasTextProp = this.text !== undefined
      const hasTextSlotContent = this.$slots.default !== undefined
      return hasTextProp || hasTextSlotContent
    }
  },
}
</script>

<style scoped lang="scss">
@use '@/styles/variables/colors';

button {
  border: 0 none;
  padding: .5em 1em;
  border-radius: 5px;

  cursor: pointer;

  &.primary {
    color: white;
    background: colors.$primary;
  }

  &.secondary {
    color: colors.$primary;
    background: colors.$button-light;
  }

  &.light {
    color: colors.$text-dark;
    background: colors.$button-light;
  }

  &.clear {
    color: unset;
    background: unset;
  }
}

button.disabled {
  color: #bcbcbf;
  background: colors.$button-light;
  cursor: unset;
}

.icon-wrapper {

  $icon-padding: 0.5em;
  &.left {
    padding-right: $icon-padding;
  }
  &.right {
    padding-left: $icon-padding;
  }
}

</style>