import {AUTH_ACQUIRED_TOKENS, AUTH_LOGOUT} from "./actions";

export const mutations = {
    [AUTH_ACQUIRED_TOKENS]: (state, {token, refreshToken}) => {
        if (!token) {
            throw new Error("updateTokens mutation called, but the token was not passed")
        }
        if (!refreshToken) {
            throw new Error("updateTokens mutation called, but the refresh token was not passed")
        }
        state.token = token;
        state.refreshToken = refreshToken;
    },
    [AUTH_LOGOUT]: state => {
        state.token = "";
        state.refreshToken = "";
    }
};