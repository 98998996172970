export default function() {
    return {
        orders: [],
        currentOrder: {
            "date": "",
            "customersReference": "",
            "invoiceIsSent": undefined,
            "isFulfilled": undefined,
            "id": undefined,
            "salesPrice": undefined,
            "comment": "",
            "user": {
                "id": undefined
            },
            "customer": {
                "id": undefined,
                "name": "",
                "orgNumber": ""
            },
            "items": [
            ],
            "consignments": [
            ],
            "comments": [
            ]
        },
        orderItemSelectionMask: []
    }
}